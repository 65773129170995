<template>
  <div v-frag>
    <div
      class="d-flex justify-center"
    >
      <fortune-wheel
        ref="FORTUNE_WHEEL"
      />
    </div>
    <v-row>
      <v-col cols="12" md="6">
        <center>
          <v-btn
            large
            depressed
            block
            color="green"
            dark
            @click="spin"
          >
            หมุนฟรี ( โค้วต้า 0 ครั้ง )
          </v-btn>
        </center>
      </v-col>
      <v-col cols="12" md="6">
        <center>
          <v-btn
            large
            depressed
            block
            color="amber"
            dark
            @click="spin"
          >
            หมุน ( ครั้งละ 50 บาท )
          </v-btn>
        </center>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import FortuneWheel from '@/components/FortuneWheel.vue';

@Component({
  components: {
    FortuneWheel,
  },
})
export default class FotuneWheelGame extends Vue {
  // eslint-disable-next-line class-methods-use-this
  spin() {
    this.$refs.FORTUNE_WHEEL.spin();
  }
}
</script>
