



































import {
  Component,
  Vue,
  Prop,
  Emit,
} from 'vue-property-decorator';
import FortuneWheelGame from '@/components/FortuneWheelGame.vue';

@Component({
  components: {
    FortuneWheelGame,
  },
})
export default class FortuneWheelDialog extends Vue {
  @Prop({ type: Boolean, required: true }) open: boolean|undefined;

  get dialog() {
    return this.open;
  }

  @Emit('close-dialog')
  // eslint-disable-next-line class-methods-use-this
  close() {
    return false;
  }
}
