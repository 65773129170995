<!-- eslint-disable max-len -->
<template>
  <div
    :style="`width: ${wrapperWidth}%;`"
  >
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 730 730">
      <g id="wheel" data-svg-origin="365 364.9499816894531" style="transform: matrix(-0.25881,0.96592,-0.96592,-0.25881,811.9835640799837,106.84306086498697); transform-origin: 0px 0px 0px;">
        <circle class="modal-frame" cx="365" cy="365" r="347.6"></circle>
        <g>
          <rect x="360.4" width="9.3" height="24.33" rx="4" ry="4"></rect>
          <rect x="352.8" y="713.2" width="24.3" height="9.27" rx="4" ry="4" transform="translate(1082.8 352.8) rotate(90)"></rect>
          <rect x="176.4" y="54.8" width="24.3" height="9.27" rx="4" ry="4" transform="translate(145.8 -133.6) rotate(60)"></rect>
          <rect x="529.2" y="665.9" width="24.3" height="9.27" rx="4" ry="4" transform="translate(851.4 -133.6) rotate(60)"></rect>
          <rect x="47.3" y="183.9" width="24.3" height="9.27" rx="4" ry="4" transform="translate(102.3 -4.5) rotate(30)"></rect>
          <rect x="658.4" y="536.8" width="24.3" height="9.27" rx="4" ry="4" transform="translate(360.5 -262.7) rotate(30)"></rect>
          <rect y="360.4" width="24.3" height="9.27" rx="4" ry="4"></rect>
          <rect x="705.7" y="360.4" width="24.3" height="9.27" rx="4" ry="4"></rect>
          <rect x="47.3" y="536.8" width="24.3" height="9.27" rx="4" ry="4" transform="translate(-262.7 102.3) rotate(-30)"></rect>
          <rect x="658.4" y="183.9" width="24.3" height="9.27" rx="4" ry="4" transform="translate(-4.5 360.5) rotate(-30)"></rect>
          <rect x="176.4" y="665.9" width="24.3" height="9.27" rx="4" ry="4" transform="translate(-486.4 498.6) rotate(-60)"></rect>
          <rect x="529.2" y="54.8" width="24.3" height="9.27" rx="4" ry="4" transform="translate(219.2 498.6) rotate(-60)"></rect>
        </g>
        <g class="modal-sectors">
          <path id="wheel_1" class="wheel-color-1" d="M365,365V35.9A328.1,328.1,0,0,0,200.5,80Z" transform="translate(0)"></path>
          <path id="wheel_2" class="wheel-color-2" d="M365,365,529.5,80A328.1,328.1,0,0,0,365,35.9Z" transform="translate(0)"></path>
          <path id="wheel_3" class="wheel-color-3" d="M365,365,650,200.5A328.5,328.5,0,0,0,529.5,80Z" transform="translate(0)"></path>
          <path id="wheel_4" class="wheel-color-1" d="M365,365H694.1A328.1,328.1,0,0,0,650,200.5Z" transform="translate(0)"></path>
          <path id="wheel_5" class="wheel-color-2" d="M365,365,650,529.5A328.1,328.1,0,0,0,694.1,365Z" transform="translate(0)"></path>
          <path id="wheel_6" class="wheel-color-3" d="M365,365,529.5,650A328.5,328.5,0,0,0,650,529.5Z" transform="translate(0)"></path>
          <path id="wheel_7" class="wheel-color-1" d="M365,365V694.1A328.1,328.1,0,0,0,529.5,650Z" transform="translate(0)"></path>
          <path id="wheel_8" class="wheel-color-2" d="M365,365,200.5,650A328.1,328.1,0,0,0,365,694.1Z" transform="translate(0)"></path>
          <path id="wheel_9" class="wheel-color-3" d="M365,365,80,529.5A328.5,328.5,0,0,0,200.5,650Z" transform="translate(0)"></path>
          <path id="wheel_10" class="wheel-color-1" d="M365,365H35.9A328.1,328.1,0,0,0,80,529.5Z" transform="translate(0)"></path>
          <path id="wheel_11" class="wheel-color-2" d="M365,365,80,200.5A328.1,328.1,0,0,0,35.9,365Z" transform="translate(0)"></path>
          <path id="wheel_12" class="wheel-color-3" d="M365,365,200.5,80A328.5,328.5,0,0,0,80,200.5Z" transform="translate(0)"></path>
          <text dy="-2rem" style="letter-spacing:4px;">
            <textPath href="#wheel_1" startOffset="50%" text-anchor="middle" style="font-size:42px;">1,000</textPath>
            <textPath href="#wheel_2" startOffset="50%" text-anchor="middle" style="font-size:42px;">10</textPath>
            <textPath href="#wheel_3" startOffset="50%" text-anchor="middle" style="font-size:42px;">5</textPath>
            <textPath href="#wheel_4" startOffset="50%" text-anchor="middle" style="font-size:42px;">50</textPath>
            <textPath href="#wheel_5" startOffset="50%" text-anchor="middle" style="font-size:42px;">500</textPath>
            <textPath href="#wheel_6" startOffset="50%" text-anchor="middle" style="font-size:42px;">5</textPath>
            <textPath href="#wheel_7" startOffset="50%" text-anchor="middle" style="font-size:42px;">5,000</textPath>
            <textPath href="#wheel_8" startOffset="50%" text-anchor="middle" style="font-size:42px;">200</textPath>
            <textPath href="#wheel_9" startOffset="50%" text-anchor="middle" style="font-size:42px;">5</textPath>
            <textPath href="#wheel_10" startOffset="50%" text-anchor="middle" style="font-size:42px;">15</textPath>
            <textPath href="#wheel_11" startOffset="50%" text-anchor="middle" style="font-size:42px;">10</textPath>
            <textPath href="#wheel_12" startOffset="50%" text-anchor="middle" style="font-size:42px;">25</textPath>
          </text>
        </g>
        <g class="modal-middle">
          <g id="modal-shadow-1" opacity="0.1"><circle cx="368.5" cy="368.5" r="54.5"></circle></g>
          <g class="modal-wheelMiddle"><circle cx="365" cy="365" r="54.5" fill="#222"></circle></g>
          <circle id="modal-middle-3" cx="365" cy="365" r="11.6" fill="#ff0000"></circle>
        </g>
      </g>
      <g id="modal-shadow-2" opacity="0.3">
        <path d="M46.9,372.5c0-181.7,147.4-329,329.1-329A327.3,327.3,0,0,1,556.3,97.2,327.3,327.3,0,0,0,365,35.9C183.3,35.9,35.9,183.3,35.9,365c0,115.2,59.2,216.5,148.8,275.3C101.3,580.6,46.9,482.9,46.9,372.5Z" transform="translate(0)"></path>
      </g>
      <g id="wheel-indicator" data-svg-origin="678.3713348388671 170.6505584716797" transform="matrix(0.99862,0.05233,-0.05233,0.99862,9.86084449889779,-35.26934183709636)" style="transform-origin: 0px 0px 0px;">
        <g>
          <path d="M707,160.5c-11.4-17.9-35.8-22.8-54.5-11a41.7,41.7,0,0,0-13.6,14.1l-33.6,58.9a2.3,2.3,0,0,0,0,2.4,2.4,2.4,0,0,0,2.3,1.1l67.5-5.1a43.8,43.8,0,0,0,18.6-6.3C712.4,202.7,718.3,178.5,707,160.5Z" transform="translate(0)" fill-opacity="0.3"></path>
          <path fill="#009a9a" d="M711.9,157.4a38.4,38.4,0,0,0-66,1.8l-31.5,57.5a2.1,2.1,0,0,0,0,2.4,2.6,2.6,0,0,0,2.2,1.2l65.6-3.9a39.6,39.6,0,0,0,17.9-5.9A38.5,38.5,0,0,0,711.9,157.4Z" transform="translate(0)"></path>
          <path d="M681.7,166.9a9.3,9.3,0,0,0-6.6,4.8l-.8,2.1a14.9,14.9,0,0,0-.2,2.1,8.8,8.8,0,0,0,1.1,4.2,9.2,9.2,0,0,0,2.9,3,7.6,7.6,0,0,0,2.9,1.3l1.1.2a8.6,8.6,0,0,0,4.2-.6,8.4,8.4,0,0,0,3.4-2.5,7.4,7.4,0,0,0,2-3.8,8.5,8.5,0,0,0-.1-4.2,8.4,8.4,0,0,0-2.1-3.8,7.4,7.4,0,0,0-3.5-2.3l-1-.3A12.2,12.2,0,0,0,681.7,166.9Z" transform="translate(0)" fill="#fff"></path>
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import {
  TimelineMax, Power1, Power4,
} from 'gsap';

let wheel;
let active;
let currentRotation;
let lastRotation;
let tolerance;
let indicator;
let spinWheel;

@Component
export default class SpinWheel extends Vue {
  target;

  get wrapperWidth() {
    if (this.$vuetify.breakpoint.mdAndUp) {
      return 70;
    }
    if (this.$vuetify.breakpoint.smOnly) {
      return 50;
    }
    return 100;
  }

  // eslint-disable-next-line class-methods-use-this
  spin() {
    spinWheel.timeScale(1).seek(0);
    indicator.timeScale(1).seek(0);
  }

  // eslint-disable-next-line class-methods-use-this
  mounted() {
    indicator = new TimelineMax({});
    spinWheel = new TimelineMax();
    console.log('indicator', indicator);
    wheel = document.getElementById('wheel');
    active = document.getElementById('wheel-indicator');

    indicator.to(
      active,
      0.13,
      {
        rotation: -10,
        transformOrigin: '65% 36%',
        ease: Power1.easeOut,
      },
    ).to(
      active,
      0.13,
      {
        rotation: 3,
        transformOrigin: '65% 36%',
        ease: Power1.easeOut,
      },
    );

    const deg = Math.floor(Math.random() * (2400 - 720 + 1)) + 720;

    spinWheel.to(wheel, 5, {
      rotation: deg,
      transformOrigin: '50% 50%',
      duration: 0,
      ease: Power4.easeOut,
      // eslint-disable-next-line object-shorthand, func-names
      onUpdate: (function () {
        // eslint-disable-next-line no-underscore-dangle
        currentRotation = this.target._gsTransform.rotation;
        console.log(currentRotation);
        tolerance = currentRotation - lastRotation;

        if (Math.round(currentRotation) % (360 / 12) <= tolerance) {
          if (indicator.progress() > 0.3 || indicator.progress() === 0) {
            indicator.play(0);
          }
        }
        lastRotation = currentRotation;
        // console.log('lastRotation', lastRotation);
      }),
    }).add('end');
  }
}
</script>

<style lang="scss" scoped>
.wheel-color-1 {
  fill: green;
}
.wheel-color-2 {
  fill: red;
}
.wheel-color-3 {
  fill: yellow;
}
</style>
